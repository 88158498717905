// Generated by Framer (a76269e)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Arrow from "./MoZTvOD6d";
const ArrowFonts = getFonts(Arrow);

const serializationHash = "framer-hwk9B"

const variantClassNames = {DdilFaGVp: "framer-v-1lx0jpu"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "DdilFaGVp", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1lx0jpu", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"DdilFaGVp"} ref={ref ?? ref1} style={{...style}}><motion.div className={"framer-1drcbvt"} layoutDependency={layoutDependency} layoutId={"WBmMwPO2D"} style={{backgroundColor: "rgb(57, 57, 57)"}}/><ComponentViewportProvider height={9} width={componentViewport?.width || "100vw"} y={((componentViewport?.y || 0) + 24 + (0 + 1 + (((((componentViewport?.height || 160) - 24) - 10) / 1) * 1)))}><motion.div className={"framer-1qlfuj2-container"} layoutDependency={layoutDependency} layoutId={"TfzAzwSjl-container"}><Arrow height={"100%"} id={"TfzAzwSjl"} layoutId={"TfzAzwSjl"} style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-hwk9B.framer-11sy6bg, .framer-hwk9B .framer-11sy6bg { display: block; }", ".framer-hwk9B.framer-1lx0jpu { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; height: 160px; justify-content: space-between; overflow: hidden; padding: 24px 0px 0px 0px; position: relative; width: 82px; }", ".framer-hwk9B .framer-1drcbvt { flex: none; height: 1px; overflow: visible; position: relative; width: 100%; }", ".framer-hwk9B .framer-1qlfuj2-container { flex: none; height: 9px; position: relative; width: 100%; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 160
 * @framerIntrinsicWidth 82
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerngRwTkJy_: React.ComponentType<Props> = withCSS(Component, css, "framer-hwk9B") as typeof Component;
export default FramerngRwTkJy_;

FramerngRwTkJy_.displayName = "Arr";

FramerngRwTkJy_.defaultProps = {height: 160, width: 82};

addFonts(FramerngRwTkJy_, [{explicitInter: true, fonts: []}, ...ArrowFonts], {supportsExplicitInterCodegen: true})